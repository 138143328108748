
.problemdesc-container{
  width: 100%;
  /* background-color:#E1F7F5; */
  height: 82vh;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.problemdesc-container__left{
  width: 56%;
  background-color:#999;
  text-align: center;
  margin-top: 1rem;
  border: 0.1rem solid #1a1a1d;
  height: 75vh;
  overflow-y: scroll;
}
.code-editor {
  margin-top: 1rem;
  width: 100%;
}

.problemdesc-container__right{
  width: 43%;
  color: black;
  font-size: 2rem;
  text-align: center;
  border: 0.1rem solid #1a1a1d;
  margin-top: 1rem;
  overflow-y: scroll;
  height: 75vh;
}
.pdesc-footer{
  /* border: 0.5rem solid black; */
  height: 20vh;
  /* overflow: auto; */
  display: grid;
  row-gap: 0;
  grid-template-rows: 1fr 5fr;
}
.pdesc-footer__header{
  display: flex;
  justify-content: start;
  align-items: center;
}
.pdesc-footer__content{
  width: 100%;
  border: 0.1rem solid #1a1a1d;
}
.pdesc-footer__content textarea{
  width: 100%;
  resize: none;
  padding: 1rem;
  height:100%;
}
.pdesc-footer button{
  padding: 1rem;
  min-width: 7rem;
  /* transform: translateX(-50%); */
}
.act{
  margin: 1rem;
  font-size: 1.6rem;
  background-color:#28b485;
  padding: 0.8rem;
  font-weight:bold;
}
.act::after{
  content: "✅";
}
.nact{
  margin: 1rem;
  font-size: 1.6rem;
  background-color:#ffb900;;
  padding: 0.8rem;
  font-weight:bold;
}
.nact::after{
  content:"❌";
}
.active{
  background-color:#FF3366;
  color: #fff;
}
.testcase-result{
  width: 100%;
  height:100%;
  display: flex;
  background-color: #fff;
  padding: 1rem;
}

.language-opt{
  width: 25rem;
  margin-top: 0.4rem;
  padding: 0.5rem;
  font-size:1.5rem;
  cursor: pointer;
  background-color: #fff;
  border: 0.2rem solid #ff9f43; 
  transition: all 0.3s ease;
  border-radius: 0.5rem;
  color: #1A1A1D;
}
.language-opt:hover {
  background-color: #ffe0b3; 
  border-color: #f5a623; 
  box-shadow: 0 0.6rem 0.8rem rgba(0, 0, 0, 0.15);
}
.language-opt:focus {
  outline: none;
  border-color: #ff6b6b; 
  box-shadow: 0 0 1rem #ff6b6b;
  background-color: #ffebe6; 
}

/* Ensure responsiveness */
@media screen and (max-width: 768px) {
  .problemdesc-container {
    flex-direction: column; /* Stack containers vertically on smaller screens */
    align-items: center; /* Center items vertically */
  }

  .problemdesc-container__left {
    width: 100%; /* Take full width on smaller screens */
    height: auto; /* Adjust height dynamically */
    margin-top: 0; /* Remove top margin */
  }

  .problemdesc-container__right {
    width: 100%; /* Take full width on smaller screens */
    height: auto; /* Adjust height dynamically */
    margin-top: 1rem; /* Add some space on top */
  }

  .pdesc-footer {
    height: auto; 
  }

  .pdesc-footer button {
    min-width: auto;
  }

  .pdesc-footer__content textarea {
    height: 10rem; 
  }
}

