.loading-box{
  width: 100%;
  background-color:black;
  color:#fff;
  height: 89vh;
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-size: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}