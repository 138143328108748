.Dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.Dashboard__bargraph, .Dashboard__piechart {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  padding: 1rem;
}

@media (min-width: 768px) {
  .Dashboard__bargraph, .Dashboard__piechart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .Dashboard__bargraph {
    margin-right: 1rem;
  }
}
