/* Global variables for colors */
:root {
  --color-primary: #3f51b5; /* Dark Blue */
  --color-secondary: #f50057; /* Pink */
  --color-grey-light: #f5f5f5; /* Light Grey */
  --color-grey-dark: #333; /* Dark Grey */
  --color-white: #fff; /* White */
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: var(--color-grey-light);
  margin: 0;
  padding: 0;
  color: var(--color-grey-dark);
}

/* Container styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

/* Problem form container */
.container-addproblem,
.container-addtestcase {
  width: 100%;
  max-width: 1200px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
}

.container-addproblem h4,
.container-addtestcase h4,
.container-addproblem__ioformat h4,
.container-addtestcase__io h4,
.container-addproblem__sampleio h4 {
  margin: 0;
  padding: 1rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
}

.container-addproblem__cont,
.container-addtestcase__pid,
.container-addtestcase__io {
  display: grid;
  grid-gap: 1rem;
}

.container-addproblem__cont textarea,
.container-addtestcase__pid textarea,
.container-addtestcase__io textarea,
.container-addproblem__ioformat-input textarea,
.container-addproblem__ioformat-output textarea,
.container-addproblem__sampleio-input textarea,
.container-addproblem__sampleio-output textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid var(--color-grey-dark);
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  resize: none; /* Non-resizable */
  transition: border-color 0.3s ease;
  min-height: 150px; /* Increased height */
}

.container-addproblem__title input[type="text"],
.container-addtestcase input[type="text"] {
  width: calc(100% - 2rem); /* Adjusted width for better padding */
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid var(--color-grey-dark);
  border-radius: 4px;
  font-size: 1.2rem; /* Increased font size for problem title */
  outline: none;
  transition: border-color 0.3s ease;
}

.container-addproblem__btn-box,
.container-addtestcase__btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.container-addproblem__btn-box button,
.container-addtestcase__btn-box button {
  padding: 1rem 2rem;
  background-color: var(--color-secondary); /* Updated button color */
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.container-addproblem__btn-box button:hover,
.container-addtestcase__btn-box button:hover {
  background-color: #c51162; /* Darker shade for hover effect */
}

/* Side by side input/output formats */
.container-addproblem__ioformat,
.container-addtestcase__io,
.container-addproblem__sampleio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.container-addproblem__ioformat h4,
.container-addtestcase__io h4,
.container-addproblem__sampleio h4 {
  margin: 0;
  padding: 1rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@media screen and (max-width: 768px) {
  .container-addproblem,
  .container-addtestcase {
    padding: 1rem;
  }

  .container-addproblem__cont,
  .container-addtestcase__pid,
  .container-addtestcase__io,
  .container-addproblem__ioformat,
  .container-addproblem__sampleio {
    grid-template-columns: 1fr;
  }

  .container-addproblem__title input[type="text"],
  .container-addtestcase input[type="text"],
  .container-addproblem__desc textarea,
  .container-addproblem__ioformat-input textarea,
  .container-addproblem__ioformat-output textarea,
  .container-addproblem__sampleio-input textarea,
  .container-addproblem__sampleio-output textarea,
  .container-addtestcase textarea {
    width: 100%;
  }
}
