.problemdesc-box {
  width: 100%;
  min-height: 90vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: 'Open Sans', sans-serif;
}

.problemtitle-box {
  font-weight: 600;
  font-size: 2rem;
  min-height: 7vh;
}

.problemstatement-box {
  min-height: 32vh;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.6rem;
}

.probleminputformat-box,
.problemoutputformat-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 1rem 0;
}

.probleminput-box,
.problemoutput-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  border: 0.2rem solid black;
  margin-bottom: 1rem;
}

.probleminput-box p,
.problemoutput-box p {
  margin-bottom: 0.5rem;
}

.problemio-box {
  min-height: 30vh;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.problemio-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.problemio-box textarea {
  width: 100%;
  resize: none;
  height: 20vh;
  padding: 1rem;
}

@media (max-width: 768px) {
  .problemdesc-box {
    font-size: 1.4rem;
  }

  .problemtitle-box {
    font-size: 1.8rem;
  }

  .probleminput-box,
  .problemoutput-box {
    height: 15vh;
  }

  .problemio-box {
    flex-direction: column;
  }

  .problemio-inner {
    grid-template-columns: 1fr;
  }
}
