.problem-box {
  width: 100%;
  color: black;
  background-color: #f9f9f9; /* Light background color for better readability */
  border: 1px solid var(--color-grey-light-3); /* Light border */
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 1rem;
}

.problem-box:hover {
  background-color: var(--color-grey-light-2); /* Slightly darker on hover */
  transform: translateY(-0.2rem); /* Slight lift on hover */
}

.problem-box li {
  list-style: none;
  border-right: 1px solid var(--color-grey-light-3); /* Light border */
  padding: 1rem;
  font-size: 1.4rem;
}

.problem-box__sr,
.problem-box__rating {
  text-align: center;
  font-weight: bold;
}

.problem-box__title {
  padding-left: 1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
}

@media (max-width: 768px) {
  .problem-box {
    grid-template-columns: 1fr 3fr 1fr;
  }

  .problem-box li {
    padding: 0.8rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .problem-box {
    grid-template-columns: 1fr 5fr;
    grid-template-rows: auto auto;
    padding: 0.5rem;
  }

  .problem-box__sr,
  .problem-box__rating {
    grid-row: 2;
    padding: 0.5rem 0;
  }

  .problem-box__title {
    grid-column: 1 / span 2;
    padding: 0.5rem 1rem;
  }
}
